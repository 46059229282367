import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import DeboursQuestion from "../../../components/questions/DeboursQuestion";

// simulateur Id 62

function Simulateur_ASF1(props) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [etat_civil, setEtatCivil] = useState(0);
  const [comedec, setComedec] = useState(0);
  const [nombre_comedec_2, setComedec2] = useState(0);
  const [nombre_comedec_1, setComedec1] = useState(0);
  const [nombre_copie_authentique, setCopieAuthentique] = useState(0);
  const [nombre_copie_libre, setCopieLibre] = useState(0);
  const [nombre_archivage, setArchivage] = useState(0);
  const [montantDeboursAdditionnels, setMontantDeboursAdditionnels] = useState(0);
  const [deboursAdditionnels, setDeboursAdditionnels] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(value);
      }

      if (setter === setEtatCivil) {
        Object.assign(object["inputs"], { etat_civil: parseInt(value) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setComedec) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(value) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setComedec2) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(value) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setComedec1) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(value) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCopieAuthentique) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(value) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCopieLibre) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(value) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setArchivage) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(value) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantDeboursAdditionnels) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(value) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setDeboursAdditionnels) {
        Object.assign(object["inputs"], { etat_civil: parseInt(etat_civil) });
        Object.assign(object["inputs"], { comedec: parseInt(comedec) });
        Object.assign(object["inputs"], { nombre_comedec_2: parseInt(nombre_comedec_2) });
        Object.assign(object["inputs"], { nombre_comedec_1: parseInt(nombre_comedec_1) });
        Object.assign(object["inputs"], { nombre_copie_authentique: parseInt(nombre_copie_authentique) });
        Object.assign(object["inputs"], { nombre_copie_libre: parseInt(nombre_copie_libre) });
        Object.assign(object["inputs"], { nombre_archivage: parseInt(nombre_archivage) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Le certificat remis par le notaire aux futurs époux avant la célébration du mariage, pour l’officier d’état
          civil, est délivré sans frais conformément aux dispositions du deuxième alinéa de l’article 1394 du Code
          civil.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Les pièces d’état-civil ont-elles été demandées ?</div>
          <ButtonYesno handleChange={handleChange} setter={setEtatCivil} defaultValue={0} />
        </div>

        {parseInt(etat_civil) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Avez-vous eu recours à Comedec ?</div>
            <ButtonYesno handleChange={handleChange} setter={setComedec} defaultValue={0} />
          </div>
        ) : (
          <></>
        )}

        {parseInt(comedec) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Indiquer le nombre de Comedec à 3,64€</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le nombre"}
              setter={setComedec2}
            />
          </div>
        ) : (
          <></>
        )}

        {parseInt(comedec) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Indiquer le nombre de Comedec à 0,64€</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le nombre"}
              setter={setComedec1}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « copie authentique » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setCopieAuthentique}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « copie libre » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setCopieLibre}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de page(s) « archivage numérisé » ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre de pages"}
            setter={setArchivage}
          />
        </div>

        <DeboursQuestion
            handleChange={handleChange}
            deboursAdditionnels={deboursAdditionnels}
            setDeboursAdditionnels={setDeboursAdditionnels}
            setMontantDeboursAdditionnels={setMontantDeboursAdditionnels}
        />

      </div>
    </div>
  );
}

export default Simulateur_ASF1;
