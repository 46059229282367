import React, {createRef, useEffect, useRef, useState} from 'react';
import './button.css'
import {findDOMNode} from "react-dom";
import useIsMountedRef from "../../utils/mountedRef";


// example of using the component: <ButtonYesno handleChange={handleChange} setter={setActeEnMain} onlyTrueFalse={true}/>

const ButtonText = (props) => {
    const isMountedRef = useIsMountedRef();

    const refSameActe = createRef();
    const refSplitActe = createRef();
    let [currentRef, setCurrentRef] = useState(null)

    function EventClick(refPassed, value) {

        refPassed.current.classList.remove("item_no_selected");

        if (refPassed !== refSameActe) {
            refSameActe.current.classList.remove('item_selected')
            refSameActe.current.classList.add('item_no_selected')
        }

        if (refPassed !== refSplitActe) {
            refSplitActe.current.classList.remove('item_selected')
            refSplitActe.current.classList.add('item_no_selected')
        }

        refPassed.current.classList.add('item_selected')

        setCurrentRef(refPassed);
        props.handleChange(props.setter, value) // launch handleChange in parent simu
    }

    useEffect(() => {
        switch (props.defaultValue) {
            case 0:
                EventClick(refSplitActe, 1);
                break;
            case 1:
                EventClick(refSameActe, 0);
                break;
            default:
                break;
        }
    }, [isMountedRef]);

    return (
        <div className={"item_ctn"}>

            <div className={"common item_no_selected item_large"} ref={refSplitActe} onClick={((e) => {
                EventClick(refSplitActe, 1)
            })}>
                Par acte séparé
            </div>

            <div className={"common item_no_selected item_large"} ref={refSameActe} onClick={((e) => {
                EventClick(refSameActe, 0)
            })}>
                Dans l'acte principal
            </div>
        </div>
    );
};

export default ButtonText;
