import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import ButtonYesno from "../../../components/buttons/button.yesno";
import SelectDepartement from "../../../components/selects/select.departement";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Adjudication volontaire de biens immobiliers
// simulateur id 31

function Simulateur_A1_7(props) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [montantVente, setMontantVente] = useState(0);
  const [montantDeboursAdditionnels, setMontantDeboursAdditionnels] = useState(0);
  const [deboursAdditionnels, setDeboursAdditionnels] = useState(0);
  const [hausse2025, setHausse2025] = useState(0);
  const [primoAccedant, setPrimoAccedant] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_vente: parseInt(montantVente) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { hausse2025: parseInt(hausse2025) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(primoAccedant) });
        object.departementid = parseInt(value);
      }

      if (setter === setMontantVente) {
        Object.assign(object["inputs"], { montant_vente: parseInt(value) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { hausse2025: parseInt(hausse2025) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(primoAccedant) });
        object.departementid = parseInt(departement);
      }

      if (setter === setDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_vente: parseInt(montantVente) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(value) });
        Object.assign(object["inputs"], { hausse2025: parseInt(hausse2025) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(primoAccedant) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_vente: parseInt(montantVente) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(value) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { hausse2025: parseInt(hausse2025) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(primoAccedant) });
        object.departementid = parseInt(departement);
      }

      if (setter === setHausse2025) {
        if (value === 0) {
          handleChange(setPrimoAccedant, 0);
        }
        Object.assign(object["inputs"], { montant_vente: parseInt(montantVente) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { hausse2025: parseInt(value) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(primoAccedant) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPrimoAccedant) {
        Object.assign(object["inputs"], { montant_vente: parseInt(montantVente) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { hausse2025: parseInt(hausse2025) });
        Object.assign(object["inputs"], { primoAccedant: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte tarifé au titre du Tableau 5, n° 69 (Art A444-102). Les débours ici comprennent les frais JAL en TTC
          ainsi que la taxe Min.Not.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Département faisant l’objet d’une hausse de sa taxe départementale ?
            <ToolTipPerso
                title={
                  "Article 116 de la loi n°2025-127 du 14 février 2025 de finances pour 2025"
                }
            />
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setHausse2025} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {hausse2025 === 1 ? (
            <div className="cardRow">
              <div style={styles.cardElemVerticalyCenter}>Bien acquis constituant une première propriété ?
                <ToolTipPerso
                    title={
                      "Le bien acquis constitue pour l’acquéreur une première propriété au sens du I de l’article L. 31-10-3 du code de la construction et de l’habitation et qu’il est destiné à l’usage de sa résidence principale."
                    }
                />
              </div>
              <div>
                <ButtonYesno handleChange={handleChange} setter={setPrimoAccedant} fieldUndefined={false} defaultValue={0} />
              </div>
            </div>
        ) : (
            <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Indiquer le montant de la vente par adjudication</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantVente}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Y a-t-il des débours à prévoir et/ou des frais de PV de quittance à ajouter et/ou des demandes d’urbanisme
            particulières ?
            <ToolTipPerso
              title={
                "Les débours sur les ventes comprennent une somme de 120 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question."
              }
            />
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setDeboursAdditionnels} fieldUndefined={true} defaultValue={2} />
          </div>
        </div>

        {parseInt(deboursAdditionnels) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Indiquer le montant :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontantDeboursAdditionnels}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_A1_7;
