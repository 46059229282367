import {styles} from "../../stylesheets/simulateurs.css";
import ButtonYesno from "../buttons/button.yesno";
import TextfieldNumber from "../textfields/textfield.number";
import React from "react";
import {ToolTipPerso} from "../selects/tooltip_perso";

// example of component use:
//      <DeboursQuestion
//          handleChange={handleChange}
//          deboursAdditionnels={deboursAdditionnels}
//          setDeboursAdditionnels={setDeboursAdditionnels}
//          setMontantDeboursAdditionnels={setMontantDeboursAdditionnels}
//      />

const DeboursQuestion = (props) => {

    return (
        <div>
            <div className="cardRow">
                <div style={styles.cardElemVerticalyCenter}>
                    Y a-t-il des débours additionnels à prévoir ?
                    <ToolTipPerso
                        title={
                            'Ce poste de débours “libre” permet d’ajuster à la hausse le montant de la provision. Par défaut nous prévoyons toujours un montant de  débours additionnels, ce dernier s’ajoute à celui que vous indiquez'
                        }
                    />
                </div>
                <div>
                    <ButtonYesno handleChange={props.handleChange} setter={props.setDeboursAdditionnels} defaultValue={0}/>
                </div>
            </div>

            {parseInt(props.deboursAdditionnels) === 1 ? (
                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>Montant des débours additionnels :</div>
                    <TextfieldNumber
                        handleChange={props.handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={props.setMontantDeboursAdditionnels}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DeboursQuestion;