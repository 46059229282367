import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import DeboursQuestion from "../../../components/questions/DeboursQuestion";

// Attestations de mutation par décès (attestation immobilière)
// Id simulateur 53, A2-13

function Simulateur_53(props) {
  const dispatch = useDispatch();

  const currentIdSimu = 53;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [premier_deces, setDeces] = useState(0);
  const [valeur_biens_communs, setCommuns] = useState(0);
  const [valeur_biens_propres, setPropres] = useState(0);
  const [valeur_biens_transmis, setBiens] = useState(0);
  const [nombre_publicite_fonciere, setSPF] = useState(0);
  const [montantDeboursAdditionnels, setMontantDeboursAdditionnels] = useState(0);
  const [deboursAdditionnels, setDeboursAdditionnels] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(value);
      }

      if (setter === setDeces) {
        Object.assign(object["inputs"], { premier_deces: parseInt(value) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCommuns) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(value) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPropres) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(value) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setBiens) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(value) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setSPF) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(value) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantDeboursAdditionnels) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(value) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setDeboursAdditionnels) {
        Object.assign(object["inputs"], { premier_deces: parseInt(premier_deces) });
        Object.assign(object["inputs"], { valeur_biens_communs: parseInt(valeur_biens_communs) });
        Object.assign(object["inputs"], { valeur_biens_propres: parseInt(valeur_biens_propres) });
        Object.assign(object["inputs"], { valeur_biens_transmis: parseInt(valeur_biens_transmis) });
        Object.assign(object["inputs"], { nombre_publicite_fonciere: parseInt(nombre_publicite_fonciere) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 1 (Art A444-59)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>S’agit-il du premier décès d’une communauté ?</div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setDeces} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {parseInt(premier_deces) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Valeur totale des biens communs transmis :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setCommuns}
            />
          </div>
        ) : (
          <></>
        )}

        {parseInt(premier_deces) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Valeur totale des biens propres transmis :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setPropres}
            />
          </div>
        ) : (
          <>
            <div className="cardRow">
              <div style={styles.cardElemVerticalyCenter}>Valeur totale des biens transmis :</div>
              <TextfieldNumber
                handleChange={handleChange}
                type={"number"}
                placeholder={"Indiquer le montant"}
                setter={setBiens}
              />
            </div>
          </>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Nombre de service de publicité foncière ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setSPF}
          />
        </div>

        <DeboursQuestion
            handleChange={handleChange}
            deboursAdditionnels={deboursAdditionnels}
            setDeboursAdditionnels={setDeboursAdditionnels}
            setMontantDeboursAdditionnels={setMontantDeboursAdditionnels}
        />

      </div>
    </div>
  );
}

export default Simulateur_53;
