import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import {ToolTipPerso} from "../../../components/selects/tooltip_perso";

// simulateur Id 21

function Simulateur_ESDM(props) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [montantDeboursAdditionnels, setMontantDeboursAdditionnels] = useState(0);
  const [deboursAdditionnels, setDeboursAdditionnels] = useState(0);
  const [montantLot1, setMontantLot1] = useState(0);
  const [montantMeuble1, setMontantMeuble1] = useState(0);
  const [montantLot2, setMontantLot2] = useState(0);
  const [montantMeuble2, setMontantMeuble2] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(montantMeuble2) });
        object.departementid = parseInt(value);
      }

      if (setter === setDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(value) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(montantMeuble2) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(value) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { "montant_meuble_1 ": parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { "montant_meuble_2 ": parseInt(montantMeuble2) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantLot1) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(value) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(montantMeuble2) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantMeuble1) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(value) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(montantMeuble2) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantLot2) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(value) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(montantMeuble2) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantMeuble2) {
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        Object.assign(object["inputs"], { montant_lot_1: parseInt(montantLot1) });
        Object.assign(object["inputs"], { montant_meuble_1: parseInt(montantMeuble1) });
        Object.assign(object["inputs"], { montant_lot_2: parseInt(montantLot2) });
        Object.assign(object["inputs"], { montant_meuble_2: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 96 (Art A444-117)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Quel est le montant du lot échangé dont la valeur est la plus élevée (y compris les meubles) ?
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantLot1}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant des meubles de ce lot ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantMeuble1}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Quel est le montant du lot échangé dont la valeur est la plus faible (y compris les meubles) ?
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantLot2}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant des meubles de ce lot ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantMeuble2}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Y a-t-il des débours additionnels à prévoir ?
            <ToolTipPerso
                title={
                  "Les débours comprennent une somme de 120 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question."
                }
            />
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setDeboursAdditionnels} fieldUndefined={true} defaultValue={2} />
          </div>
        </div>

        {parseInt(deboursAdditionnels) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Montant des débours additionnels :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontantDeboursAdditionnels}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_ESDM;
