import React, { useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonCessionFDC from "../../../components/buttons/button.cessionFDC";
import ButtonCessionFDC2 from "../../../components/buttons/button.cessionFDC2";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import { Typography } from "@mui/material";
import DeboursQuestion from "../../../components/questions/DeboursQuestion";

// Cession de fonds de commerce
// simulateur Id 32

function Simulateur_32(props) {
  const dispatch = useDispatch();

  const currentIdSimu = 32;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const texteAvecSautsDeLigne1 =
    " - L’acquéreur est salarié de l’entreprise cédée depuis plus de 2 ans ou parent du cédant conjoint, descendant ligne directe, partenaire pacs, ascendant, collatéraux privilégiés \n- engagement de reprise pendant 5 ans minimum \n- biens ou droits sociaux détenus depuis plus de 2 ans.";

  const lignes1 = texteAvecSautsDeLigne1.split("\n");

  const texteAvecSautsDeLigne2 =
    " - Vente de marchandises neuves (biens transformés objets du commerce ou matières premières) ;\n - Achat en vue de la revente dans les 5 ans ; \n - Achat par les collectivités territoriales et autres établissements (communes, syndicats de communes, départements, régions)";

  const lignes2 = texteAvecSautsDeLigne2.split("\n");

  const [departement, setDepartement] = useState(0);
  const [montant_cession, setCession] = useState(0);
  const [montant_honoraires, setHonoraires] = useState(0);
  const [regime_cession, setRegimeCession] = useState(0);
  const [acquisition_zone_geographique, setZoneGeo] = useState(0);
  const [cession_fond_artisanal, setFondArt] = useState(0);
  const [conditions_speciales, setCondSpe] = useState(0);
  const [acquisition_brevet, setBrevet] = useState(0);
  const [brevet_vendu_seul, setVente] = useState(0);
  const [brevet_exploite, setExploitation] = useState(0);
  const [marchandises_neuves, setMarchandises] = useState(0);
  const [montantDeboursAdditionnels, setMontantDeboursAdditionnels] = useState(0);
  const [deboursAdditionnels, setDeboursAdditionnels] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(value);
      }

      if (setter === setCession) {
        Object.assign(object["inputs"], { montant_cession: parseInt(value) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setHonoraires) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(value) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setRegimeCession) {
        if (value === 0) {
          handleChange(setZoneGeo, 0);
          handleChange(setFondArt, 0);
          handleChange(setBrevet, 0);
          handleChange(setMarchandises, 0);
        }
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(value) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setZoneGeo) {
        if (value === 1) {
          handleChange(setFondArt, 0);
          handleChange(setBrevet, 0);
          handleChange(setMarchandises, 0);
        }
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(value) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setFondArt) {
        if (value === 1) {
          handleChange(setZoneGeo, 0);
          handleChange(setBrevet, 0);
          handleChange(setMarchandises, 0);
        } else if (value === 0) {
          handleChange(setCondSpe, 0);
        }
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(value) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setCondSpe) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(value) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setBrevet) {
        if (value === 1) {
          handleChange(setZoneGeo, 0);
          handleChange(setFondArt, 0);
          handleChange(setMarchandises, 0);
        } else if (value === 0) {
          handleChange(setVente, 0);
          handleChange(setExploitation, 0);
        }
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(value) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setVente) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(value) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setExploitation) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(value) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMarchandises) {
        if (value === 1) {
          handleChange(setZoneGeo, 0);
          handleChange(setFondArt, 0);
          handleChange(setBrevet, 0);
        }
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(value) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(value) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(deboursAdditionnels) });
        object.departementid = parseInt(departement);
      }

      if (setter === setDeboursAdditionnels) {
        Object.assign(object["inputs"], { montant_cession: parseInt(montant_cession) });
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { regime_cession: parseInt(regime_cession) });
        Object.assign(object["inputs"], { acquisition_zone_geographique: parseInt(acquisition_zone_geographique) });
        Object.assign(object["inputs"], { cession_fond_artisanal: parseInt(cession_fond_artisanal) });
        Object.assign(object["inputs"], { conditions_speciales: parseInt(conditions_speciales) });
        Object.assign(object["inputs"], { acquisition_brevet: parseInt(acquisition_brevet) });
        Object.assign(object["inputs"], { brevet_vendu_seul: parseInt(brevet_vendu_seul) });
        Object.assign(object["inputs"], { brevet_exploite: parseInt(brevet_exploite) });
        Object.assign(object["inputs"], { marchandises_neuves: parseInt(marchandises_neuves) });
        Object.assign(object["inputs"], { montant_deb_add: parseInt(montantDeboursAdditionnels) });
        Object.assign(object["inputs"], { debours_additionnels: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 100 (Art A444-120)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant fixé pour la cession ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setCession}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Indiquer le montant HT des honoraires prévues (s’il y en a) :
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setHonoraires}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>De quel régime dépend la cession ?</div>
          <div>
            <ButtonCessionFDC
              handleChange={handleChange}
              setter={setRegimeCession}
              fieldUndefined={false}
              defaultValue={0}
            />
          </div>
        </div>
        {parseInt(regime_cession) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>
              La cession correspond-t-elle à une acquisition en zone géographique spécifique ou une acquisition par les
              collectivités locales ?
              <ToolTipPerso
                title={
                  "Zones franches urbaines - territoires entrepreneurs (ZFU-TE) ou zones de revitalisation rurale à condition d'engagement de reprise pour 5 ans minimum."
                }
              />
            </div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setZoneGeo}
              fieldUndefined={false}
              defaultValue={acquisition_zone_geographique}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(regime_cession) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>
              La cession correspond-t-elle à une cession en pleine propriété d’un fonds artisanal ?
              <ToolTipPerso
                title={"Ou commercial, agricole, clientèle d’une entreprise individuelle ou parts/actions de société."}
              />
            </div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setFondArt}
              fieldUndefined={false}
              defaultValue={cession_fond_artisanal}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(cession_fond_artisanal) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>
              Les conditions sont-elles respectées ?
              <ToolTipPerso
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {lignes1.map((ligne, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {ligne}
                      </React.Fragment>
                    ))}
                  </Typography>
                }
              />
            </div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setCondSpe}
              fieldUndefined={false}
              defaultValue={conditions_speciales}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(regime_cession) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>
              La cession correspond-t-elle à une acquisition de brevet ou marque ?
            </div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setBrevet}
              fieldUndefined={false}
              defaultValue={acquisition_brevet}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(acquisition_brevet) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Est-il vendu :</div>
            <ButtonCessionFDC2
              handleChange={handleChange}
              setter={setVente}
              fieldUndefined={false}
              defaultValue={brevet_vendu_seul}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(acquisition_brevet) === 1 && parseInt(brevet_vendu_seul) === 0 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Est-il exploité ?</div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setExploitation}
              fieldUndefined={false}
              defaultValue={brevet_exploite}
            />
          </div>
        ) : (
          <></>
        )}
        {parseInt(regime_cession) === 1 ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>
              La cession correspond-t-elle à une vente de marchandises neuves / ou un achat en vue de la revente dans
              les 5 ans / ou un achat par les collectivités territoriales et autres établissements ?
              <ToolTipPerso
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {lignes2.map((ligne, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {ligne}
                      </React.Fragment>
                    ))}
                  </Typography>
                }
              />
            </div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setMarchandises}
              fieldUndefined={false}
              defaultValue={marchandises_neuves}
            />
          </div>
        ) : (
          <></>
        )}

        <DeboursQuestion
            handleChange={handleChange}
            deboursAdditionnels={deboursAdditionnels}
            setDeboursAdditionnels={setDeboursAdditionnels}
            setMontantDeboursAdditionnels={setMontantDeboursAdditionnels}
        />

      </div>
    </div>
  );
}

export default Simulateur_32;
